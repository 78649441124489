<template>
    <div>
        <a @click="viewDetails" v-if="data.bounce != 0"  style="cursor: pointer; color: blue">{{ data.bounce.toFixed(0)}}%</a>
        <a  v-else style="cursor: pointer; color: black">{{ data.bounce }}</a>

        <b-modal
            v-model="modalShow"
            id="modal-xl"
            size="xl"
            :title="'Bounce Rate (Sub-report)'"
            :hide-footer="true"
        >
            <div class="row">
                <div class="col-12 col-lg-12 pt-3">
                    <ejs-grid
                        ref="grid"
                        id="Grids"
                        :dataSource="data.allBounces"
                        :allowPaging="true"
                        :allowExcelExport='true'
                        :pageSettings='pageSettings'
                        :filterSettings="filterSettings"
                        :allowSorting ='true'
                        :allowFiltering="true"
                    >
                        <e-columns>

                            <e-column field='data.tos.start' headerText='Date of interaction' :template='startDatetemplate'></e-column>
<!--                            <e-column field='data.tracker' headerText='Tracker'></e-column>-->
<!--                            <e-column field='data.type' headerText='Type'></e-column>-->
                            <e-column field='data.link' headerText='Domain'></e-column>
                            <e-column field='data.tos.seconds' headerText='Time on site' :template="timeOnSitetemp"></e-column>
                            <e-column field='data.tos.endTime' headerText='Date\Time of Bounce' :template='endDatetemplate'></e-column>
                        </e-columns>

                    </ejs-grid>
                </div>
            </div>

        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';

/*import VSelect from 'vue-select'*/
import {GridPlugin, Page, Sort, ExcelExport, Aggregate, Filter, } from "@syncfusion/ej2-vue-grids";
import moment from "moment-timezone";

Vue.use(GridPlugin);
export default {
    name: "bounceRatetemplate",
    components: {},
    provide: {
        grid: [Page, Sort, ExcelExport, Aggregate, Filter, ]
    },
    // props:['summary'],
    data: function() {
        return {
            counter: this.details,
            counterCheck: 0,
            counerCheckTwo:1,
            data: {},
            repo:[],
            report:[],
            reports:[],
            proxieDataSet: [],
            filter:{
                to: '',
                from: '',
                status:'' ,
                provider:'',
                page:'',

            },
            modalTitle:"Modal Title",
            modalShow:false,
            pageSettings: {pageSize: 25},
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },

            startDatetemplate: function () {
                return {
                    template: Vue.component('dateTemplate', {
                        template: `
                            <div id="dateTemplate" class="bg-active">
                            <span class="text-success"  >{{timeconversion(data.data.tos.start,this.$store.state.user.timezone)}}</span>
<!--                            <span  v-else >{{data.date}}</span>-->
                            </div>`,
                        data: function () { return { data: {}}; },  methods:{

                            timeConverter :(time) => new Date('2020-01-01 ' + time).getTime(),
                            timeconversion : (time,timezone) =>{
                                if(time == undefined){
                                    console.log('undefined 1',time)
                                    return 'undefined'
                                }else{
                                    console.log('undefined timezone',timezone)
                                    return  moment(time).tz(timezone).format('YYYY-MM-DD hh:mm:ss')
                                }

                            } ,

                            toTime: (s)=> new Date(s * 1000).toISOString().substr(11, 8),
                        }
                    })
                }
            },
            endDatetemplate: function () {
                return {
                    template: Vue.component('dateTemplate', {
                        template: `
                            <div id="dateTemplate" class="bg-active">
                            <span class="text-success" v-if="timeconversion(data.data.tos.endTime,this.$store.state.user.timezone) != 'undefined'" >{{timeconversion(data.data.tos.endTime,this.$store.state.user.timezone)}}</span>
                            </div>`,
                        data: function () { return { data: {}}; },  methods:{

                            timeConverter :(time) => new Date('2020-01-01 ' + time).getTime(),
                            timeconversion : (time,timezone) =>{
                                if(time == undefined){
                                    return 'undefined'
                                }else{
                                    return  moment(time).tz(timezone).format('YYYY-MM-DD hh:mm:ss ')
                                }

                            } ,
                            toTime: (s)=> new Date(s * 1000).toISOString().substr(11, 8),
                        }
                    })
                }
            },
            timeOnSitetemp: function () {
                return {
                    template: Vue.component('dateTosTemplate', {
                        template: `
                            <div id="dateTosTemplate" class="bg-active">
                            <span  >{{toTime(data.data.tos.seconds)}}</span>
                            </div>`,
                        data: function () { return { data: {}}; },  methods:{
                            toTime: (s)=> new Date(s * 1000).toISOString().substr(11, 8),
                        }
                    })
                }
            },


        }
    },
    created() {
      this.data.timezone =  this.$store.state.user.timezone
    },
    methods:{
        viewDetails: function(){
            this.modalTitle = `Report Details (${this.data.name})`;
            this.modalShow = true;
        },
        customAggregateFn : function (data) {
            return data.result.filter((item) => item.error).length;
        },


    },
}
</script>

<style scoped>

</style>
