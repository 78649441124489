<template>
    <div>
        <a @click="viewDetails"  style="cursor: pointer; color: blue">{{toTime(data.avg_tos) }}</a>

        <b-modal
            v-model="modalShow"
            id="modal-xl"
            size="xl"
            :title="'Time On Site (Sub-report)'"
            :hide-footer="true"
        >
            <div class="row">
                <div class="col-12 col-lg-12 pt-3">
                    <ejs-grid
                        ref="grid"
                        id="Grids"
                        :dataSource="[data]"
                        :allowPaging="true"
                        :allowExcelExport='true'
                        :pageSettings='pageSettings'
                        :filterSettings="filterSettings"
                        :allowSorting ='true'
                        :allowFiltering="true"
                        :detailTemplate ="'detailTemplate'"

                    >
                        <e-columns>
                            <e-column field='date' headerText='Date' :template ='datetemplate' ></e-column>
                            <!-- <e-column field='data.tracker' headerText='Tracker'></e-column> -->
                            <!-- <e-column field='data.type' headerText='Type'></e-column> -->
                            <e-column field='domain' headerText='Domain'></e-column>
                            <e-column field='total' headerText='Pages Visited Count (Total)'  ></e-column>
                            <e-column field='tos' headerText='Time on site (Total)' :template='tosTemplate' ></e-column>
                        </e-columns>

                        <template v-slot:detailTemplate="{data}">
                            <ejs-grid :dataSource="data.data" :pageSettings='pageSettings'>
                                <e-columns>
                            <!-- <e-column field='data.tracker' headerText='Tracker'></e-column> -->
<!--                            <e-column field='data.type' headerText='Type'></e-column>-->
<!--                            <e-column field='data.link' headerText='Link'></e-column>-->
                                    <e-column field='data.link' headerText='Pages Visited'  ></e-column>
                                    <e-column field='data.tos.seconds' headerText='Time on Page' :template='timeOnSiteTemplate' ></e-column>
                                </e-columns>
                            </ejs-grid>
                        </template>




                    </ejs-grid>
                </div>
            </div>

        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';

/*import VSelect from 'vue-select'*/
import {GridPlugin, Page, Sort, ExcelExport, Aggregate, Filter,DetailRow} from "@syncfusion/ej2-vue-grids";
import moment from "moment-timezone";



Vue.use(GridPlugin);

export default {
    name: "timeOnSiteTemplate",
    components: {},

    provide: {
        grid: [Page, Sort, ExcelExport, Aggregate, Filter,DetailRow  ]
    },
    // props:['summary'],
    data: function() {
        return {
            counter: this.details,
            counterCheck: 0,
            counerCheckTwo:1,
            data: {},
            repo:[],
            report:[],
            reports:[],
            proxieDataSet: [],
            filter:{
                to: '',
                from: '',
                status:'' ,
                provider:'',
                page:'',

            },
            modalTitle:"Modal Title",
            modalShow:false,
            pageSettings: {pageSize: 25},
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            datetemplate: function () {
                return {
                    template: Vue.component('dateTemplate', {
                        template: `
                            <div id="dateTemplate" class="bg-active">
                            <span>{{timeconversion(data.date,this.$store.state.user.timezone)}}</span>
                            </div>`,
                        data: function () { return { data: {}}; },  methods:{

                            timeconversion : (date,timezone) =>{
                                if(date == undefined){
                                    return 'undefined'
                                }else{
                                    return  moment(date).tz(timezone).format('YYYY-MM-DD')
                                }

                            } ,
                        }
                    })
                }
            },
            //:v-if="(emailvarifier)? advarifier = false :advarifier = true "
            tosTemplate: function () {
                return {
                    template: Vue.component('statusTemplate', {
                        template: `
                            <div id="tos" class="bg-active">
                            <span class="text-danger" v-if="timeConverter(toTime(data.tos)) <= timeConverter('00:00:30')">{{toTime(data.tos)}}</span>
                            <span style="color: #f67f0e" v-else-if="timeConverter(toTime(data.tos)) > timeConverter('00:00:30') && timeConverter(toTime(data.tos)) <= timeConverter('00:00:59')">{{toTime(data.tos)}}</span>
                            <span class="text-warning" v-else-if="timeConverter(toTime(data.tos)) >= timeConverter('00:01:00') && timeConverter(toTime(data.tos)) <= timeConverter('00:02:00')">{{ toTime(data.tos)}}</span>
                            <span class="text-success" v-else-if="timeConverter(toTime(data.tos)) > timeConverter('00:02:00') && timeConverter(toTime(data.tos)) < timeConverter('00:04:00')">{{toTime(data.tos)}}</span>
                             <span class="text-primary" v-else-if="timeConverter(toTime(data.tos)) >= timeConverter('00:04:00')">{{ toTime(data.tos) }}</span>

                            </div>`,
                        data: function () { return { data: {}}; },  methods:{
                            timeConverter :(time) => new Date('2020-01-01 ' + time).getTime(),
                            toTime: (s)=> new Date(s * 1000).toISOString().substr(11, 8),
                        }
                    })
                }
            },
            timeOnSiteTemplate: function () {
                return {
                    template: Vue.component('tosTemplate', {
                        template: `
                            <div id="tosTemp" class="bg-active">
                            <span class="text-danger" v-if="timeConverter(toTime(data.data.tos.seconds)) <= timeConverter('00:00:30')">{{toTime(data.data.tos.seconds)}}</span>
                            <span style="color: #f67f0e" v-else-if="timeConverter(toTime(data.data.tos.seconds)) > timeConverter('00:00:30') && timeConverter(toTime(data.data.tos.seconds)) <= timeConverter('00:00:59')">{{toTime(data.data.tos.seconds)}}</span>
                            <span class="text-warning" v-else-if="timeConverter(toTime(data.data.tos.seconds)) >= timeConverter('00:01:00') && timeConverter(toTime(data.data.tos.seconds)) <= timeConverter('00:02:00')">{{ toTime(data.data.tos.seconds)}}</span>
                            <span class="text-success" v-else-if="timeConverter(toTime(data.data.tos.seconds)) > timeConverter('00:02:00') && timeConverter(toTime(data.data.tos.seconds)) < timeConverter('00:04:00')">{{toTime(data.data.tos.seconds)}}</span>
                            <span class="text-primary" v-else-if="timeConverter(toTime(data.data.tos.seconds)) >= timeConverter('00:04:00')">{{ toTime(data.data.tos.seconds) }}</span>

                            </div>`,
                        data: function () { return { data: {}}; },  methods:{

                            timeConverter :(time) => new Date('2020-01-01 ' + time).getTime(),
                            toTime: (s)=> new Date(s * 1000).toISOString().substr(11, 8),
                        }
                    })
                }
            },


        }
    },
    methods:{
        viewDetails: function(){
            this.modalTitle = `Report Details (${this.data.name})`;
            this.modalShow = true;
        },
        timeConverter :function(time) {
            return  new Date('2020-01-01 ' + time).getTime()
        },
        customAggregateFn : function (data) {
            return data.result.filter((item) => item.error).length;
        },

        toTime: (s) => new Date(s * 1000).toISOString().substr(11, 8),

    },


}
</script>

<style scoped>

</style>
